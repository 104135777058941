<template>
  <div>
    <!-- 隐私协议弹窗 -->
    <van-popup
      v-model="localShow"
      position="bottom"
      custom-style="height: 50%;"
      @click-overlay="onCloseOverlay"
    >
      <div class="agreeBox">
        <div class="tit">停车隐私政策</div>
        <div class="content">
          <!-- <span class="content_span"
            >让停车更便捷，杭州育恩科技有限公司为您提供停车相关的服务和产品。</span
          ><br/>
          <span class="content_span"
            >我们深知个人信息对您的重要性，为此始终致力于采取技术措施和其他必要措施以确保您的信息安全。</span
          ><br/>
          <span class="content_span">
            因此，我们将遵守相关法律法规的规定进行个人信息的收集、使用、储存、披露和保护，并制定如下《枫林停车用户隐私政策》（下称“本政策”）来帮助您了解如下内容:
          </span> -->
          <!-- <pre>{{policyInfo}}</pre>  -->
          {{policyInfo}}

        </div>

        <div class="btns">
          <!-- <van-button class="btn" type="default">取消</van-button> -->
          <button @click="cancelBtn" class="cancelBtn">
            <span class="span">拒绝</span>
          </button>
          <button @click="confirmlBtn" class="confirmlBtn">
            <span class="span">同意</span>
          </button>
          <!-- <van-button class="btn" type="info" color="##4470FC">同意</van-button> -->
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import * as constant from '../api/constant'
export default {
  name: 'PrivacyPolicy',
  props: ['show'],
  data () {
    return {
      localShow: this.show,
      policyInfo: constant.policyInfo
    }
  },
  mounted () {

  },
  computed: {

  },
  watch: {
    show (newVal) {
      // 监听 prop 的变化，并将其赋值给本地的 data 属性
      this.localShow = newVal
    }
  },
  methods: {
    cancelBtn () {
      this.$emit('get-cancel', 2)
    },
    confirmlBtn () {
      this.$emit('get-success', 1)
    },
    onCloseOverlay () {
      this.$emit('get-polocy-show')
    }

  }
}
</script>
<style lang="scss" scoped>
/* 隐私弹窗 */
.agreeBox {
  padding: 0.16rem;
}

.tit {
  color: #000;
  font-size: 0.18rem;
  // margin-bottom: 0.16rem;
  margin-top: 0.05rem;
}

.content {
  // display: flex;
  // padding: 0.08rem;
  // max-width: 3rem;
  color: #000;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: left;
  white-space: pre-wrap;
  // overflow-x: hidden;
  .content_span{
    // text-indent: 2em !important;
    // margin-left: 2em;
  }

}

.btns {
  /* margin-bottom: 80rpx; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.16rem;
}

.btns .btn {
  margin-top: 0.16rem;
  /* margin-bottom: 80rpx; */
  /* margin-left: 50rpx; */
}

.cancelBtn,
.confirmlBtn {
  width: 1.4rem;
  height: 0.4rem;
  /* border: 1rpx solid; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmlBtn {
  background-color: #4470fc;
  color: #fff;
  font-size: 0.15rem;
  border: 0.01rem solid #4470fc;
}

.cancelBtn {
  background-color: #fff;
  color: #4470fc;
  font-size: 0.16rem;
  border: 0.01rem solid #4470fc;
}
</style>
